
<template>
  <div>
    <div v-if="flag" class="map-wrap">
      <div class="mapTitle">
        <span>{{dataList.mapTitle}}</span>
        <div class="close">
          <Iconfont :size="16" @click="screenClick" name="icon-quanping1"></Iconfont>
          <ElIcon
            :size="20"
            class="icon-close"
            @click.stop="closeMap"
            name="el-icon-close"
          ></ElIcon>
        </div>
      </div>
      <BSJMap ref="mapRef" v-if="flag" :center="mapCenter" :zoom="getZoom()">
        <!-- 信息窗体 -->
        <InfoWindow
          v-model="showInfo"
          :showClose="true"
          :lngLat="getInfoLngLat()"
          :offset="[10, -15]"
          :width="332"
          :height="180"
          :color="'#fff'"
        >
          <div class="window-title">{{selectObj.plate}}</div>
          <div class="window-info">
            <div class="info-wrap">
              <div class="info-item">
                <label>车辆号码</label>
                <strong>{{selectObj.plate || '--'}}</strong>
              </div>
              <div class="info-item">
                <label>车辆颜色</label>
                <strong>{{selectObj.plateColor || '--'}}</strong>
              </div>
              <div class="info-item">
                <label>当前报警</label>
                <strong>
                  <TextScroll v-if="selectObj.alarm" :text="selectObj.alarm"></TextScroll>
                  <span v-else>--</span>
                </strong>
              </div>
              <!-- <div class="info-item">
                <label>车辆状态</label>
                <strong>{{selectObj.online}}</strong>
              </div>-->
              <div class="info-item">
                <label>车辆位置</label>
                <strong>
                  <TextScroll
                    v-if="selectObj.address"
                    :text="selectObj.address"
                  ></TextScroll>
                  <span v-else>--</span>
                </strong>
              </div>
            </div>
            <div class="info-img">
              <Iconfont
                class="avatorImg"
                :size="60"
                :name="vehicleIconName(this.selectObj)"
              ></Iconfont>

              <!-- <img markerimg :src="defaultIcon" /> -->
            </div>
          </div>
        </InfoWindow>
        <!-- 变量 pathType 区分 -->
        <!-- 圆形 41 -->
        <MapCircle
          v-if="dataList.pathType===41"
          :center="dataList.pointList[0]"
          :radius="dataList.radius"
          autoFitView
        ></MapCircle>
        <!-- 折线 42 -->
        <MapPolyline
          v-if="dataList.pathType===42"
          :path="dataList.pointList"
          showDir
          editable
          autoPanTo
          panToInView
          autoFitView
        ></MapPolyline>

        <!-- 矩形 43 -->
        <MapRectangle
          v-if="dataList.pathType===43"
          :bounds="dataList.pointList"
          editable
          autoPanTo
          panToInView
          autoFitView
        ></MapRectangle>

        <!-- 多边形 44 -->
        <MapPolygon
          v-if="dataList.pathType===44"
          :path="dataList.pointList"
          editable
          autoFitView
        ></MapPolygon>
        <!-- todoList -->
        <template v-if="dataList.pathType===100">
          <MapPolygon
            v-for="(item,index) in dataList.pointList"
            :key="index"
            :path="item"
            editable
            autoFitView
          ></MapPolygon>
        </template>
        <!-- 车辆聚合 -->
        <MapClusterer
          :points="vehicleList"
          pointKey="id"
          :markerOffset="[15, -10]"
          @markerClick="markerClick($event)"
        >
          <template #marker="{point}">
            <Iconfont :size="32" :name="vehicleIconName(point)"></Iconfont>
            <!-- <Iconfont :size="32" name="icon-a-zu517"></Iconfont> --> 
            <!-- <img markerimg :src="defaultIcon" /> -->
          </template>
        </MapClusterer>

        <!--搜索的点-->
        <MapMarker
          v-if="markerLngLat.length"
          :lngLat="markerLngLat"
          :offset="[15, -10]"
          :rotate="0"
          autoFitView
          autoPanTo
        >
          <Iconfont :size="32" name="icon-a-zu517"></Iconfont>
          <!-- <Iconfont name="icon-shishidingwei" :size="32"></Iconfont> -->
        </MapMarker>
      </BSJMap>

      <el-autocomplete
        class="placeSearch"
        prefix-icon="el-icon-search"
        v-model="searchPlace"
        :fetch-suggestions="querySearch"
        placeholder="请输入内容"
        :trigger-on-focus="false"
        @select="handleSelect"
      >
        <template slot-scope="{ item }">
          <div class="name" :title="item.name">{{ item.name }}</div>
        </template>
      </el-autocomplete>
    </div>
    <img src="../../assets/images/分组监控添加.png" @click="chooseGroup" v-else />
    <ChooseGroup v-model="dialogVisible" @on-confirm="selectNode" />
  </div>
</template>

<script>
import GPS from "@/utils/address";
import { getAddress, getAddresses } from '@/components/AMap/utils';
import ChooseGroup from './ModalChoose';
import { districtSearch } from '@/components/AMap/utils/districtSearch';
import { placeSearch } from '@/components/AMap/utils';
import TextScroll from '@/components/TextScroll';


import { getPointMsg, getCustomAreaVehicle, getRegionCodeVehicle } from '@/api/getRule.js';
import BSJMap, {
  InfoWindow,
  MapMarker,
  MapPolyline,
  MapPolygon,
  MapRectangle,
  MapCircle,
  MapTrack,
  MapClusterer,
} from '@/components/AMap';

// import { getProductTypeIconName } from '@/utils/getProductTypeIconName';
import vehiclePicture from '@/utils/vehiclePicture';

export default {
  // `name`与路由保持一致
  name: 'MapItem',

  components: {
    ChooseGroup,
    BSJMap,
    InfoWindow,
    MapMarker,
    MapPolyline,
    MapPolygon,
    MapRectangle,
    MapCircle,
    MapTrack,
    MapClusterer,
    TextScroll,
  },
  computed: {
    closeIcon() {
      return require('@/assets/images/header/关闭.png');
    }
  },
  data() {
    return {
      showInfo: false,
      selectObj: {},
      defaultIcon: require('@/assets/images/默认点标记.png'),
      //监控弹框
      dialogVisible: false,
      flag: false,
      dataList: { pointList: [] },
      vehicleList: [],
      locationList: [],
      activeName: '区域',
      searchPlace: '',// 输入的地点
      markerLngLat: [], // 搜索的经纬度
      mapCenter: [116.482169, 39.998932],
      markerOffset: [5, 0]
    };
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
    // 获取iconName
    vehicleIconName(data) {
      // return getProductTypeIconName(data.machineryProductType, data.machineryEquipmentType);
      return vehiclePicture(data.machineryProductType, data.machineryEquipmentType, (data.Z || data.vehicleStatus));
    },

    // 输入建议
    async querySearch(queryString, cb) {
      const result = await placeSearch(this.searchPlace);

      cb(result);
    },

    handleSelect(item) {
      const { location } = item;

      this.searchPlace = item.name;
      this.markerLngLat = [location.lng, location.lat];
      // return (restaurant) => {
      //   return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      // };
    },

    // 全屏点击
    screenClick() {
      this.$emit('on-screen');
    },

    // 获取车辆位置数据
    getLocation(locationList) {
      getAddresses(locationList).then(res => {
        this.vehicleList = this.vehicleList.map(item => {
          let matchAddress = res.find(subItem => item.terminalNo === subItem.tag);
          if (matchAddress) return { ...item, address: matchAddress.address };
          else return { ...item };
        });
      });
    },
    // 根据区域或省市区返回放大等级
    getZoom() {
      if (this.activeName === '区域') return 15;
      else return 7;
    },
    // 获取中心点
    getCenter() {
      const params = this.dataList.pointList[0];

      if (this.activeName === '区域') return [params.lng, params.lat];
      else return [params.lng, params.lat];
    },
    // 获取marker的坐标
    getInfoLngLat() {
      let arr = Object.keys(this.selectObj);
      if (arr.length !== 0) {
        return [this.selectObj.lng, this.selectObj.lat];
      } else {
        if (this.vehicleList.length) return [this.vehicleList[0].lon, this.vehicleList[0].lat];
        else return [];
      }
    },
    //点击
    markerClick(val) {
      this.selectObj = val;
      this.showInfo = true;
    },
    // 接收选择区域的节点数据、标题和 区分省市区及区域的标志
    selectNode({ node, title, activeName }) {
      this.activeName = activeName;
      if (activeName === '区域') {
        if (node.pathType === 30) { return this.$message.warning('文件夹类型无法绘制区域，请重新选择'); }
        this.getRegion(node, title);
      }
      else this.getProvince(node, title);
    },
    // 查询区域
    getRegion(node, title) {
      getPointMsg({ pathId: node.pathId }).then(res => {
        // 数据成功返回
        if (res.flag === 1) {

          // 没有区域位置数据则返回
          if (res.obj.pointList.length === 0) {
            this.closeMap();
            return this.$message.warning('查询区域：查询为空');
          }

          //纠偏
          let pointList = res.obj.pointList.map(item => {
            let point = GPS.delta(item.oriLat, item.oriLon);
            return [point.lon, point.lat];
          });

          // 圆形区域需要多添加字段 radius
          if (res.obj.pathType === 41) {
            let radius = res.obj.pointList[0].radius;
            this.dataList = { ...res.obj, pointList, mapTitle: title, radius };
          }

          // 矩形、多边形等区域
          else {
            this.dataList = { ...res.obj, pointList, mapTitle: title };
          }
          //绘制区域后查询区域内车辆
          this.getRegionVehicle(node.pathId);
          // 显示地图
          this.flag = true;
        }
        //数据返回有问题
        else {
          this.$message.error(res.msg);
        }
      }).catch(err => {
        console.log(err);
      });
    },
    // 查询区域下的车辆
    getRegionVehicle(pathId) {
      getCustomAreaVehicle({ pathId }).then(res => {
        // 数据返回成功
        if (res.flag === 1) {
          // 有数据
          if (res.obj) {
            this.locationList = [];
            this.vehicleList = res.obj.data.map((item, i) => {
              // 纠偏
              let point = GPS.delta(item.latitude, item.longitude);
              this.locationList.push({ lon: point.lon, lat: point.lat, tag: item.terminalNo });
              return { ...item, id: i, lng: point.lon, lon: point.lon, lat: point.lat, address: '' };
            });
            // 根据车辆ID匹配address对应项
            this.getLocation(this.locationList);
          }
          //无数据
          else {
            this.vehicleList = [];
            this.$message.warning(`查询车辆：${res.msg}`);
          }
        }
        // 数据返回有问题
        else {
          this.$message.error(res.msg);
        }
      })
        //数据返回失败
        .catch(err => console.log(err));
    },
    // 查询省市区

    getProvince(node, title) {
      districtSearch(node.code, node.grade).then(res => {
        //高德返回的数据无需纠偏
        let pointList = res.map(item => {
          return item.map(subItem => {
            return [subItem.lng, subItem.lat];
          });
        });
        // 绘制地图
        this.dataList = { pointList, mapTitle: title, pathType: 100 };
        // 显示地图
        this.flag = true;
        // 默认为区
        let level = 3; //省市区等级（1:省，2：市，3：区）
        if (node.grade === 'province') level = 1;
        else if (node.grade === 'city') level = 2;
        // 根据省市区等级和代码查询区域内车辆
        this.getProvinceVehicle({ code: node.code, level });
      })
        .catch(err => console.log(err));
    },
    // 查询省市区下的车辆
    getProvinceVehicle({ code, level }) {
      getRegionCodeVehicle({ code, level }).then(res => {
        if (res.flag === 1) {
          // 有数据
          if (res.obj) {
            this.locationList = [];
            this.vehicleList = res.obj.data.map((item, i) => {
              // 纠偏
              let point = GPS.delta(item.latitude, item.longitude);
              this.locationList.push({ lon: point.lon, lat: point.lat, tag: item.terminalNo });
              return {
                ...item,
                id: i,
                lng: point.lon,
                lon: point.lon,
                lat: point.lat,
                address: ''
              };
            });

            // 根据车辆ID匹配address对应项
            this.getLocation(this.locationList);
          }
          //无数据
          else {
            this.vehicleList = [];
            this.$message.warning(`查询车辆：${res.msg}`);
          }
        }
      })
        .catch(err => console.log(err));
    },
    // 记录选择窗口的下标
    chooseGroup() {
      this.dialogVisible = true;
    },
    // 关闭窗口
    closeMap() {
      this.showInfo = false;
      this.flag = false;
      this.dataList = { pointList: [] };
      this.vehicleList = [];
      this.$emit('close');
    },
  }
}
</script>

<style lang="scss" scoped>
.item {
  text-align: center;
  border: 2px solid #e9e9e9;
  background: #6b7c9b;
  position: relative;
  & > img {
    width: 60px;
    height: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -30px;
    margin-top: -30px;
    cursor: pointer;
  }
}

.map-wrap {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.placeSearch {
  width: 140px;
  position: absolute;
  top: 45px;
  right: 80px;
}

.name {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mapTitle {
  color: #fff;
  background: #212d3e;
  padding: 0 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 33px;
  .close {
    cursor: pointer;
    .icon-close {
      margin-left: 10px;
    }
  }
}
.window-title {
  width: 100%;
  background: #212d3e;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 15px;
  padding: 6px 12px;
}
.window-info {
  display: flex;
  .info-wrap {
    width: calc(100% - 100px);
    overflow: hidden;
    .info-item {
      display: flex;
      margin: 12px 0 12px 6px;
      font-size: 14px;
      justify-content: flex-start;
      label {
        color: #ccc;
        margin-right: 20px;
        white-space: nowrap;
      }
      strong {
        text-align: start;
        color: #303133;
      }
    }
  }

  .info-img {
    width: 100px;
    align-self: center;
  }
}
</style>

