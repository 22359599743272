<template>
  <div v-if="modal">
    <el-dialog
      width="415px"
      :visible="modal"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <!-- header -->
      <div slot="title" class="header-title">
        <span>选择区域进行监控</span>
        <span class="exit-icon" title="关闭" @click="onModalChange(false)">
          <i size="32" class="el-icon-close"></i>
        </span>
      </div>
      <!-- body -->
      <el-tabs type="border-card" :stretch="true" v-model="activeName">
        <el-tab-pane label="区域" name="区域">
          <div class="modal-main">
            <AreaTree ref="AreaTree" @on-change="selectArea" />
          </div>
        </el-tab-pane>
        <el-tab-pane label="省市" name="省市">
          <div class="modal-main">
            <AreaTree ref="CityTree" @on-change="selectArea" :getCity="true" />
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="title-wrap">
        <label for="itemTitleInput">标题：</label>
        <el-input v-model="itemTitle" placeholder="请输入标题" id="itemTitleInput"></el-input>
      </div>
      <!-- footer -->
      <span slot="footer" class="bottom">
        <el-button size="mini" @click="onModalChange(false)">取 消</el-button>
        <el-button size="mini" type="primary" @click="onSaveData(false)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import SelectGroupTree from '@/components/GroupTree/SelectGroupTree';
import AreaTree from '@/components/AreaTree';
export default {
  name: 'ModalChoose',
  components: {
    SelectGroupTree,
    AreaTree,
  },
  model: {
    prop: 'modal',
    event: 'modal-change'
  },
  props: {
    modal: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      activeName: '区域',
      //选中的区域对象
      selectNode: null,
      //该区域的标题名称
      itemTitle: ''
    };
  },

  watch: {
    modal: {
      // immediate: true,
      async handler(val) {
        this.itemTitle = '';
        if (!(val && this.iProps)) return;
        //打开操作
      }
    },
  },

  methods: {
    selectArea({ node }) {
      this.selectNode = node;
    },
    onModalChange(modal, eventName = 'on-cancel', ...options) {
      this.$emit('modal-change', !!modal);
      this.$emit(eventName, ...options);
    },
    //校验区域和标题，并传给父组件
    onSaveData(modal) {
      if (this.selectNode && this.itemTitle) {
        this.$emit('modal-change', !!modal);
        this.$emit('on-confirm', { node: this.selectNode, title: this.itemTitle, activeName: this.activeName });
      }
      else this.$message.warning('请选择区域并输入名称');
    },

    checkChange(node, checked) {
      console.log(node, checked);
    },
    // 树形图单击事件，查询table数据
    selectVGroup(manageWay) {
      console.log(manageWay);
    },
    confirmList() { },
  }
}
</script>

<style lang="scss" scoped>
.el-dialog {
  padding: 0;
}
.modal-main {
  height: 300px;
  overflow: auto;
}

.header {
  position: relative;
}
.exit-icon {
  // position: absolute;
  // right: 0px;
  // top: 0px;
  cursor: pointer;
  width: 20px;
  // text-align: center;
}
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-tabs--border-card {
  border: 0;
  box-shadow: none;
}

::v-deep .el-tabs__content {
  padding: 0;
}
.header-title {
  background: #212d3e;
  color: #fff;
  padding: 15px 20px;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
::v-deep .el-dialog__header {
  padding: 0;
}
.title-wrap {
  display: flex;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  label {
    width: 60px;
  }
}
</style>

